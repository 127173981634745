<template>
  <div style="height: 713px" class="loading" v-if="about_data === undefined && !about_error">
    <Loading />
  </div>
  <section id="about" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000" class="about-section ptb-50" v-else-if="about_data">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="about-image image-wrap l-right">
            <DefaultImage :id="about_data.data[0].image" :width="'100%'" />
          </div>
          <div>
            <div class="about-area-content">
              <h3>{{about_data.data[0].title}}</h3>
            </div>

            <div class="about-tab mt-3 white-space-pre-wrap" v-html="about_data.data[0].text"></div>

            <router-link to="/about" class="events-btn-one">
              <i class="flaticon-curve-arrow"></i> More About PeaceJam
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import DefaultImage from "./DefaultImage";
  import Loading from "./Loading";
  import api from "../services/api";
  import useSWRV from "swrv";

  export default {
    name: "AboutArea",
    components: {Loading, DefaultImage},
    setup() {
      let {
        data: about_data,
        error: about_error,
        isValidating: about_isValidating
      } = useSWRV(() => `items/our_vision?status=published`, api.fetcher);

      return {
        about_data,
        about_error,
        about_isValidating,
      }
    },
  }
</script>

<style scoped lang="scss">
  .events-btn-one {
    font-size: 15px;
    color: #FC4040;
    font-weight: bold;
    display: inline-block;
    margin-right: 30px;
    margin-top: 15px;
  }
  .about-section {
    background-color: #e0edef !important;
    background-image: none;
  }

  .white-space-pre-wrap {
    line-height: 0.5;
  }

  .about-image {
    border: 1px solid #ccc;
  }

  .about-area-content {
    h3 {
      font-size: 30px;
      color: #fc4040;
      font-weight: 600;
    }
  }

  @media (max-width: 991px) {
    .events-btn-one {
      margin-bottom: 20px;
    }
  }
</style>
